.bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // background-color: #AAA;
    z-index: -10;
    margin: 0;
    padding:0;
    padding-bottom: 20px;

    background: rgb(170,170,170);
    background: linear-gradient(113deg, rgba(170,170,170,1) 0%, rgba(153,153,153,1) 51%, rgba(119,119,119,1) 100%);
}

.floatingBtnLayout{
    position: absolute;
    bottom: 0;
    // display: flex;
    // width: 100%;
    // justify-content: center;
    // align-items: center;
    // margin: 0;
    // padding: 0;
    // background-color: red;
    // align-self: center;
    // justify-self: center;

    // margin-left: -10vw;
    // margin-right: 10vw;
    // overflow: hidden;

    button{
        margin: 0;
        padding: 0;
    }

    .arrowIcon{
        filter: drop-shadow(1px 3px 2px #c0392b);

        
    }

    .textIcon{


    }
    &:hover{

        .textIcon{
            scale: 1.05;
        }

        .arrowIcon{
            scale: 1.1;
        }

        
    }
    
}

.landingContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;

    @media (max-width: 600px) { // xs size
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-top: 5vh !important;
    }
    
    @media (max-width: 900px) { // sm size
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-top: 10vh;
    }
    @media (min-width: 900px) { // sm size
        flex-wrap: nowrap;
    }
    // order: 3;
    &.xl{
     
        
        
    }
    &.lg{
        
    }
    &.md{

        // flex-wrap: wrap;
        
    }
    &.sm{
        // flex-wrap: wrap;
    }
    .logo{
        
        height: 35vh;
        width: auto;
        // transform: scaleX(-1);
        
        &.xl{
            flex-wrap: nowrap;
            
            
        }
        &.lg{
            // margin-left: 50px;
            scale: 1.5;
            flex-wrap: nowrap;
            
        }
        &.md{
            // flex-direction: column-reverse;
            margin-left: 30px;
            flex-wrap: wrap;
            
        }
        &.sm{
            // font-size: 4vh;
            margin-right: 30px;
            @media (max-width: 900px) { // sm size
                flex-wrap: wrap;
                flex-direction: column-reverse;
                justify-content: flex-end;
                margin-top: 10vh;
            }
            
            
        }
        &.xs{
            // font-size: 4vh;
            // margin-right: 30px;
            // scale: 0.5;
            
            
        }
    }

    .logoContainer{
        &.xl{
            margin-right: 50px;
            flex-wrap: nowrap !important;
        }
        &.lg{
            margin-left: 50px;
            flex-wrap: nowrap !important;
            
        }

        // flex-direction: row-reverse;
        
        &.md{
            justify-content: space-between;
        }

        &.sm{
            // font-size: 4vh;
            
            // text-align: center;
            flex-direction: column;

            
            .logo{
                flex: 1;
                height: auto;
                width: 200px;
                // padding-bottom: 300px;
                align-self: flex-start;
                justify-self: flex-start;
            }
        }

    }

    .title1{
        color: white;
        text-align: right;
        font-weight: 400;
        
        
        
        &.xl{
            font-size: 7vh;
            text-shadow: -4px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
            
        }
        &.lg{
            font-size: 5vh;
            text-shadow: -2px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
            
        }
        &.md{
            font-size: 2vh;
            
        }
        &.sm{
            font-size: 4vh;
            text-align: center;
            // margin-top: 200px;
            
        }
        &.xs{
            font-size: 3vh;
            text-align: center;
            @media (min-width: 450px) {
                font-size: 3vh;
              }
            
        }
        // font-size: ;
    }

    .title2{
        color: white;
        font-weight: 900;
        font-style: bold;
       

        
        &.xl{
            font-size: min(8.1vh,12vw );
            text-shadow: -6px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
        }
        &.lg{
            font-size: min(7.2vh,12vw );
            text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
        }
        &.md{
            font-size: min(4.5vh,12vw );
            text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
        }
        &.sm{
            font-size: min(6.0vh,12vw );
            align-self: center;
            justify-self: center;
            text-align: center;
            text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
            
        }
        &.xs{
            font-size: min(4.0vh,19vw );
            align-self: center;
            justify-self: center;
            text-align: center;
            text-shadow: -1px 0 #999, 0 1px #999, 1px 0 #999, 0 -1px #999;

            @media (min-width: 450px) {
                font-size: min(4.5vh,19vw );
              }
            
        }
        // font-size: 120%;
        // font-size: ;
    }
    .subtitle{
        color: white;
        font-weight: 300;
        font-size: min(2.7vh,5vw );
        z-index: 9999;
        &.xl{
            font-size: min(3vh,5vw );
            font-weight: 300;
            // style={{
            //     flex: 1,
            //     alignItems: "flex-start",
            //     justifyContent: "center",
            //     flexDirection: "row",
            //   }}
        }
        &.md{
            font-size: min(1.3vh,5vw );
            font-weight: 500;
            
        }
        &.sm{
            font-size: min(2.2vh,5vw );
            align-self: center;
            justify-self: center;
            text-align: center;
            margin-bottom: 10px;
            
        }
        &.xs{
            margin-bottom: 10px;
            font-size: min(1.9vh,5vw );
            align-self: center;
            justify-self: center;
            text-align: center;
            font-weight: 500;
            @media (min-width: 450px) {
                font-size: min(1.7vh,5vw );
              }
        }
    }
    .phone{
        color: white;
        font-weight: 300;
        font-size: min(2.7vh,5vw );
        z-index: 9999;
        &.xl{
            font-size: min(2.5vh,5vw );
            font-weight: 300;
            
        }
        &.md{
            font-size: min(1.3vh,5vw );
            font-weight: 500;
            
        }
        &.sm{
            font-size: min(4.2vh,5vw );
            align-self: center;
            justify-self: center;
            text-align: center;
            
        }
        &.xs{
            font-size: 3.2vh;
            align-self: center;
            justify-self: center;
            text-align: center;
            // font-weight: 400;
            // @media (min-width: 450px) {
            //     font-size: min(2.1vh,5vw );
            //   }
        }
    }


}

.contactInfoLayout{

    
    @media (min-width: 900px) { // sm size
    }

    @media (max-width: 900px) { // sm size
        flex-wrap: wrap;
        // align-items: flex-start;
    }

    .mapa{
        width: 500px;

        &.xs{
            width: 300px !important;

            margin-bottom: 200px;
        }
    }
}

.asadsadasdsad{
    // font-size: 200pt;
    // margin-top: 30px;
    padding-top: 100px;
    min-height: 100vh;
    

    align-items: center;
    justify-content: center;
    display: flex;

    &.contacto{

        margin-top: 61px;
        // background-color: red;
    }
    &.servicios{

        // background-color: green;

        .serviciosLayout{

            flex-wrap: wrap;

            @media (min-width: 900px) { // sm size
                flex-wrap: nowrap;
            }

        }

        .imgServContainer{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .imgServ{
                display: block;
                max-width:330px;
                max-height:295px;
                width: auto;
                height: auto;
                margin: 10px;

                border: 1px solid #e74c3c;
                border-radius: 5px;
            }
        }

        
    }
  }

  .linkImg{
    &:hover {
        opacity: 0.8;
    }
  }
.videoGalery{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

.videoItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    
}

.videoLink{
    

    &.rtve{
    border:3px solid #e74c3c;
    border-radius: 5px;

    @media (max-width: 600px) { // xs size
        width:340px;
        height:195px;
    }
    
    @media (max-width: 900px) { // sm size
        width:340px;
        height:195px;
        &.lg{
            display: none;
        }
    }
    @media (min-width: 900px) { // sm size
        width:680px;
        height:490px;

        &.xl{
            display: none;
        }
    }
    }

    &.etb{padding: 10px;}
  }
 

  .balls-container-2{
    position: absolute;
    // background-color: yellow;
    height: 100%;
    width: inherit;
    left: 0;
    height: 100vh;

    pointer-events: none;
    z-index: -1;

    .ball{
        position: absolute;
        background-color: #111;
        border-radius: 50%;
        overflow: hidden;
    }

    .ball1{
        // bottom: calc(50% - 19vh);
        bottom: -16vh;
        left: calc(50% - 15vw);
        width: 34vw;
        height: 35vh;
    }
    .ball2{
        // bottom: calc(50% - 15vh);
        bottom: -10vh;
        left: calc(50% - 33vw);
        width: 32vw;
        height: 28vh;
    }
    .ball3{
        // bottom: calc(50% - 17vh);
        bottom: -9vh;
        left: calc(50% );
        width: 32vw;
        height: 26vh;
    }

    .ball4{
        // bottom: calc(50% - 17vh);
        bottom: -9vh;
        left: 10%;
        width: 32vw;
        height: 26vh;
    }
    .ball5{
        // bottom: calc(50% - 17vh);
        bottom: -9vh;
        left: 60%;
        width: 32vw;
        height: 26vh;
    }
    .ball6{
        // bottom: calc(50% - 17vh);
        bottom: -15vh;
        left: 60%;
        width: 22vw;
        height: 26vh;
    }

  }

.balls-container{
    position: fixed;
    // background-color: red;
    height: 100vh;
    width: 100vw;

    pointer-events: none;
    z-index: -1;

    .ball{
        position: absolute;
        background-color: #111;
        border-radius: 50%;
        overflow: hidden;
    }

    .ball1{
        bottom: -10vw;
        right: -5vw;
        width: 34vw;
        height: 34vw;
    }

    .ball2{
        bottom: -10vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
    .ball3{
        bottom: -15vw;
        left: 5vw;
        width: 30vw;
        height: 30vw;
    }
    .ball4{
        bottom: -9vw;
        left: 50vw;
        width: 25vw;
        height: 25vw;
    }
    .ball5{
        bottom: 12vw;
        left: 30vw;
        width: 5vw;
        height: 5vw;
    }
    .ball6{
        bottom: 20vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
    .ball7{
        bottom: -10vw;
        left: -10vw;
        width: 32vw;
        height: 32vw;
    }
    .ball8{
        bottom: 17vw;
        left: 0vw;
        width: 10vw;
        height: 10vw;
    }
    .ball9{
        bottom: 12vw;
        right: -13vw;
        width: 20vw;
        height: 20vw;
    }
    .ball10{
        bottom: 7vw;
        left: 67vw;
        width: 12vw;
        height: 12vw;
    }
    .ball2{
        bottom: -10vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
    .ball2{
        bottom: -10vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
    .ball2{
        bottom: -10vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
    .ball2{
        bottom: -10vw;
        left: -10vw;
        width: 20vw;
        height: 20vw;
    }
}

