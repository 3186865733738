


html { font-family: 'Roboto', sans-serif; color: #333; }

.App{
  display: flex;
  flex-direction: column;
}

a{
    text-decoration: none;
    color: #333;
    // &:hover{
    //     color:#555
    // }
}

.hashLink{
  &:hover{
    color: #AAA !important;
  }
}

.header{
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.embed-layout {
    width: 100%;
    height: 54vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .youtube-embed {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .MuiImageListItem-root{
    // border: 10px solid red;
  }

  .MuiImageListItem-img {
    // &.right{
    //   object-position: 90% 50%;
    // }
    // background-color: red;
    // border: 10px solid red;
    // transform-origin: right;
    // transform: translateX(-50%)
    // object-fit: none !important; 
    // 
    &.right{
      object-position: 90% 50%;
    }
   
  }