.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  background: rgb(170, 170, 170);
  background: linear-gradient(113deg, rgb(170, 170, 170) 0%, rgb(153, 153, 153) 51%, rgb(119, 119, 119) 100%);
}

.floatingBtnLayout {
  position: absolute;
  bottom: 0;
}
.floatingBtnLayout button {
  margin: 0;
  padding: 0;
}
.floatingBtnLayout .arrowIcon {
  filter: drop-shadow(1px 3px 2px #c0392b);
}
.floatingBtnLayout:hover .textIcon {
  scale: 1.05;
}
.floatingBtnLayout:hover .arrowIcon {
  scale: 1.1;
}

.landingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
@media (max-width: 600px) {
  .landingContainer {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 5vh !important;
  }
}
@media (max-width: 900px) {
  .landingContainer {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 10vh;
  }
}
@media (min-width: 900px) {
  .landingContainer {
    flex-wrap: nowrap;
  }
}
.landingContainer .logo {
  height: 35vh;
  width: auto;
}
.landingContainer .logo.xl {
  flex-wrap: nowrap;
}
.landingContainer .logo.lg {
  scale: 1.5;
  flex-wrap: nowrap;
}
.landingContainer .logo.md {
  margin-left: 30px;
  flex-wrap: wrap;
}
.landingContainer .logo.sm {
  margin-right: 30px;
}
@media (max-width: 900px) {
  .landingContainer .logo.sm {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 10vh;
  }
}
.landingContainer .logoContainer.xl {
  margin-right: 50px;
  flex-wrap: nowrap !important;
}
.landingContainer .logoContainer.lg {
  margin-left: 50px;
  flex-wrap: nowrap !important;
}
.landingContainer .logoContainer.md {
  justify-content: space-between;
}
.landingContainer .logoContainer.sm {
  flex-direction: column;
}
.landingContainer .logoContainer.sm .logo {
  flex: 1;
  height: auto;
  width: 200px;
  align-self: flex-start;
  justify-self: flex-start;
}
.landingContainer .title1 {
  color: white;
  text-align: right;
  font-weight: 400;
}
.landingContainer .title1.xl {
  font-size: 7vh;
  text-shadow: -4px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title1.lg {
  font-size: 5vh;
  text-shadow: -2px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title1.md {
  font-size: 2vh;
}
.landingContainer .title1.sm {
  font-size: 4vh;
  text-align: center;
}
.landingContainer .title1.xs {
  font-size: 3vh;
  text-align: center;
}
@media (min-width: 450px) {
  .landingContainer .title1.xs {
    font-size: 3vh;
  }
}
.landingContainer .title2 {
  color: white;
  font-weight: 900;
  font-style: bold;
}
.landingContainer .title2.xl {
  font-size: min(8.1vh, 12vw);
  text-shadow: -6px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title2.lg {
  font-size: min(7.2vh, 12vw);
  text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title2.md {
  font-size: min(4.5vh, 12vw);
  text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title2.sm {
  font-size: min(6vh, 12vw);
  align-self: center;
  justify-self: center;
  text-align: center;
  text-shadow: -3px 0 #999, 0 3px #999, 1px 0 #999, 0 -1px #999;
}
.landingContainer .title2.xs {
  font-size: min(4vh, 19vw);
  align-self: center;
  justify-self: center;
  text-align: center;
  text-shadow: -1px 0 #999, 0 1px #999, 1px 0 #999, 0 -1px #999;
}
@media (min-width: 450px) {
  .landingContainer .title2.xs {
    font-size: min(4.5vh, 19vw);
  }
}
.landingContainer .subtitle {
  color: white;
  font-weight: 300;
  font-size: min(2.7vh, 5vw);
  z-index: 9999;
}
.landingContainer .subtitle.xl {
  font-size: min(3vh, 5vw);
  font-weight: 300;
}
.landingContainer .subtitle.md {
  font-size: min(1.3vh, 5vw);
  font-weight: 500;
}
.landingContainer .subtitle.sm {
  font-size: min(2.2vh, 5vw);
  align-self: center;
  justify-self: center;
  text-align: center;
  margin-bottom: 10px;
}
.landingContainer .subtitle.xs {
  margin-bottom: 10px;
  font-size: min(1.9vh, 5vw);
  align-self: center;
  justify-self: center;
  text-align: center;
  font-weight: 500;
}
@media (min-width: 450px) {
  .landingContainer .subtitle.xs {
    font-size: min(1.7vh, 5vw);
  }
}
.landingContainer .phone {
  color: white;
  font-weight: 300;
  font-size: min(2.7vh, 5vw);
  z-index: 9999;
}
.landingContainer .phone.xl {
  font-size: min(2.5vh, 5vw);
  font-weight: 300;
}
.landingContainer .phone.md {
  font-size: min(1.3vh, 5vw);
  font-weight: 500;
}
.landingContainer .phone.sm {
  font-size: min(4.2vh, 5vw);
  align-self: center;
  justify-self: center;
  text-align: center;
}
.landingContainer .phone.xs {
  font-size: 3.2vh;
  align-self: center;
  justify-self: center;
  text-align: center;
}

@media (max-width: 900px) {
  .contactInfoLayout {
    flex-wrap: wrap;
  }
}
.contactInfoLayout .mapa {
  width: 500px;
}
.contactInfoLayout .mapa.xs {
  width: 300px !important;
  margin-bottom: 200px;
}

.asadsadasdsad {
  padding-top: 100px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}
.asadsadasdsad.contacto {
  margin-top: 61px;
}
.asadsadasdsad.servicios .serviciosLayout {
  flex-wrap: wrap;
}
@media (min-width: 900px) {
  .asadsadasdsad.servicios .serviciosLayout {
    flex-wrap: nowrap;
  }
}
.asadsadasdsad.servicios .imgServContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.asadsadasdsad.servicios .imgServContainer .imgServ {
  display: block;
  max-width: 330px;
  max-height: 295px;
  width: auto;
  height: auto;
  margin: 10px;
  border: 1px solid #e74c3c;
  border-radius: 5px;
}

.linkImg:hover {
  opacity: 0.8;
}

.videoGalery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.videoGalery .videoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.videoLink.rtve {
  border: 3px solid #e74c3c;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .videoLink.rtve {
    width: 340px;
    height: 195px;
  }
}
@media (max-width: 900px) {
  .videoLink.rtve {
    width: 340px;
    height: 195px;
  }
  .videoLink.rtve.lg {
    display: none;
  }
}
@media (min-width: 900px) {
  .videoLink.rtve {
    width: 680px;
    height: 490px;
  }
  .videoLink.rtve.xl {
    display: none;
  }
}
.videoLink.etb {
  padding: 10px;
}

.balls-container-2 {
  position: absolute;
  height: 100%;
  width: inherit;
  left: 0;
  height: 100vh;
  pointer-events: none;
  z-index: -1;
}
.balls-container-2 .ball {
  position: absolute;
  background-color: #111;
  border-radius: 50%;
  overflow: hidden;
}
.balls-container-2 .ball1 {
  bottom: -16vh;
  left: calc(50% - 15vw);
  width: 34vw;
  height: 35vh;
}
.balls-container-2 .ball2 {
  bottom: -10vh;
  left: calc(50% - 33vw);
  width: 32vw;
  height: 28vh;
}
.balls-container-2 .ball3 {
  bottom: -9vh;
  left: 50%;
  width: 32vw;
  height: 26vh;
}
.balls-container-2 .ball4 {
  bottom: -9vh;
  left: 10%;
  width: 32vw;
  height: 26vh;
}
.balls-container-2 .ball5 {
  bottom: -9vh;
  left: 60%;
  width: 32vw;
  height: 26vh;
}
.balls-container-2 .ball6 {
  bottom: -15vh;
  left: 60%;
  width: 22vw;
  height: 26vh;
}

.balls-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  z-index: -1;
}
.balls-container .ball {
  position: absolute;
  background-color: #111;
  border-radius: 50%;
  overflow: hidden;
}
.balls-container .ball1 {
  bottom: -10vw;
  right: -5vw;
  width: 34vw;
  height: 34vw;
}
.balls-container .ball2 {
  bottom: -10vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball3 {
  bottom: -15vw;
  left: 5vw;
  width: 30vw;
  height: 30vw;
}
.balls-container .ball4 {
  bottom: -9vw;
  left: 50vw;
  width: 25vw;
  height: 25vw;
}
.balls-container .ball5 {
  bottom: 12vw;
  left: 30vw;
  width: 5vw;
  height: 5vw;
}
.balls-container .ball6 {
  bottom: 20vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball7 {
  bottom: -10vw;
  left: -10vw;
  width: 32vw;
  height: 32vw;
}
.balls-container .ball8 {
  bottom: 17vw;
  left: 0vw;
  width: 10vw;
  height: 10vw;
}
.balls-container .ball9 {
  bottom: 12vw;
  right: -13vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball10 {
  bottom: 7vw;
  left: 67vw;
  width: 12vw;
  height: 12vw;
}
.balls-container .ball2 {
  bottom: -10vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball2 {
  bottom: -10vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball2 {
  bottom: -10vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}
.balls-container .ball2 {
  bottom: -10vw;
  left: -10vw;
  width: 20vw;
  height: 20vw;
}/*# sourceMappingURL=landing.css.map */