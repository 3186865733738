html {
  font-family: "Roboto", sans-serif;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: #333;
}

.hashLink:hover {
  color: #AAA !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.embed-layout {
  width: 100%;
  height: 54vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embed-layout .youtube-embed {
  width: 100%;
  height: 100%;
  border: none;
}

.MuiImageListItem-img.right {
  -o-object-position: 90% 50%;
     object-position: 90% 50%;
}/*# sourceMappingURL=index.css.map */